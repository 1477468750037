import React from 'react';
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Listing from './pages/Listing';
import AllListing from './pages/AllListing';
import AddNew from './pages/AddNew';
import './App.css';

const App = () => {
  return(
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Listing" element={<Listing />} />
      <Route path="/AllListing" element={<AllListing />} />
      <Route path="/AddNew" element={<AddNew />} />
    </Routes>
  )
};

export default App;
