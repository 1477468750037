import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import bg from "../images/frontpagebg3.png";
import logo from "../images/WispLogo.png";
// import {ConnectButton, Icon, Select, DatePicker, Input, Button} from "web3uikit";
import {ConnectButton, Icon, DatePicker, Input, Button} from "web3uikit";
import Select from 'react-select';
import {useState} from "react";

const Home = () => {
  const [projectType, setProjectType] = useState("Wireless Internet");
  const [destination, setDestination] = useState("New York");
  const [startDate, setStartDate] = useState(new Date());
  const [minAmount, setMinAmount] = useState(1);

  return (
    <>
      <div className="container" style={{backgroundImage: `url(${bg})` }}>
        <div className="containerGradinet"></div>
      </div>
        <div className="topBanner">
          <div>
            <img className="logo" src={logo} alt="logo"></img>
          </div>
          <div className="tabs">
            <div className="selected">Investment Opportunities</div>
            <div> Education</div>
          </div>
          <div className="lrContainers">
            <ConnectButton />
          </div>
        </div>
        <div className="tabContent">
          <div className="searchFields">
            <div className="inputs">
              Project Type
              <Select 
                defaultOptionIndex={0}
                onChange={(data) => setProjectType(data.label)}
                options={[               
                  {
                    id: "wisp",
                    label: "Wireless Internet"
                  },
                  {
                    id: "solar",
                    label: "Solar"
                  },           
                  {
                    id: "wind",
                    label: "Wind"
                  },
                ]}
              />
            </div>
            <div className="vl" />
            <div className="inputs">
              Location
              <Select 
                defaultOptionIndex={4}
                onChange={(data) => setDestination(data.label)}
                options={[
                  {
                    id: "co",
                    label: "Colorado"
                  },                    
                  {
                    id: "fl",
                    label: "Florida"
                  },                   
                  {
                    id: "ga",
                    label: "Georgia"
                  },
                  {
                    id: "ma",
                    label: "Massachusetts"
                  },          
                  {
                    id: "ny",
                    label: "New York"
                  },
                  {
                    id: "ok",
                    label: "Oklahoma"
                  },
                  {
                    id: "ri",
                    label: "Rhode Island"
                  },                  
                  {
                    id: "tx",
                    label: "Massachusetts"
                  },
                  {
                    id: "paraguay",
                    label: "Mbokaja Puku, Paraguay"
                  },
                ]}
              />
            </div>
            <div className="vl" />
            <div className="inputs">
              Cash Flow Start Date
              <DatePicker
                id="StartDate"
                onChange={(event)=> setStartDate(event.date)}
              />
            </div>
            <div className="vl" />
            <div className="inputs">
              Invest Amount
              <Input
                value={1.0}
                name="AddInvestment"
                type="number"
                onChange={(event) => setMinAmount(Number(event.target.value))}
              />
            </div>
            <Link to={"/listing"} state={{
              projectType: projectType,
              destination: destination,
              startDate: startDate,
              minAmount: minAmount
            }}>          
            <div className="searchButton">
                <Icon fill="#ffffff" size={24} svg="search" />
            </div>
            </Link> 
          </div>
        </div>
        
        <div className="randomLocation">
          <div className="randomLocationL">
            <div className="title">Want To Explore?</div>
            <div className="text">Let us show you all the exciting investment opportunities!</div>
            <div className="centerContainers">
              <Link to={"/alllisting"} state={{
                projectType: "All",
                destination: "All",
                startDate: "Any",
                minAmount: minAmount
              }}> 
              <Button 
              text = "Start Browsing"
              theme = "colored"
              color = "green"
              // onClick={() => {window.open('/alllisting', '_blank')}}
              /> 
              </Link> 
            </div>
          </div>

          <div className="randomLocationR">
            <div className="title">Don't see any place you like?</div>
            <div className="text">Please add a new location you want to invest</div>
            <div className="centerContainers">
              <Link to={"/addnew"} > 
              <Button 
              text = "Make a Wish"
              theme = "colored"
              color = "blue"
              /> 
              </Link> 
            </div>
          </div>
        </div>

    </>
  );
};

export default Home;
