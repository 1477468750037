import React from "react";
import "./Listing.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import logo from "../images/WispLogo.png";
import { ConnectButton, Icon, Button, useNotification } from "web3uikit";
import RentalsMap from "../components/RentalsMap";
import { useState, useEffect} from "react";
import { useMoralis, useWeb3ExecuteFunction } from "react-moralis";
import User from "../components/User";

const Listing = () => {
  const {state: searchFilters} = useLocation();
  const {highLight, setHighLight} = useState();
  const {Moralis, account} = useMoralis();
  const [dealsList, setDealsList] = useState();
  const [coOrdinates, setCoOrdinates] = useState([]);
  const contractProcessor = useWeb3ExecuteFunction();
  const dispatch = useNotification();

  const handleSuccess =() => {
    dispatch({
      type: "success",
      message: `Nice! You are investing in ${searchFilters.destination}!`,
      title: "Investing succeed",
      position: "topL",
    });
  };

  const handleError =(msg) => {
    dispatch({
      type: "error",
      message: `${msg}`,
      title: "Investing failed",
      position: "topL",
    });
  };

  const handleNoAccount =() => {
    dispatch({
      type: "error",
      message: "You need to connect your wallet to make an investment",
      title: "Not connected",
      position: "topL",
    });
  };

  useEffect(() => {
    async function fetchDealsList() {
      const Deals = Moralis.Object.extend("Deals");  // "Deals" is the name of the table
      const queryProjectType = new Moralis.Query(Deals);
      queryProjectType.equalTo("stringStuff", searchFilters.projectType);
      const queryDestination = new Moralis.Query(Deals);
      queryDestination.equalTo("stringStuff", searchFilters.destination);
      // TODO: right now only filter by projectType and destination. Cannot search by min investment amount or cash flow date in array field?
      // TODO: allow open criteria on certain fields if left empty
      const query = Moralis.Query.and(queryProjectType, queryDestination);
      const result = await query.find();
      let resultList = [];
      result.forEach((e) => {
        resultList.push({attributes: {
          name: e.get("stringStuff")[0],
          projectType: e.get("stringStuff")[1],
          state: e.get("stringStuff")[2],
          lat: e.get("stringStuff")[3],
          lng: e.get("stringStuff")[4],
          description: e.get("stringStuff")[5],
          imgUrl: e.get("stringStuff")[6],
          cashFlowStartDate: e.get("stringStuff")[7],
          minInvestAmount: e.get("numberStuff")[0]/ 10000,
          maxInvestAmount: e.get("numberStuff")[1]/ 10000,
          tokenPrice: e.get("numberStuff")[2]/(10**14),
          numTokenAvailable: e.get("numberStuff")[3]/10000,
          holdingPeriodByMonth: e.get("numberStuff")[4],
          ROI: e.get("numberStuff")[5]/1000,
          numTokenSold: e.get("numTokenSold"),
          uid_decimal: e.get("uid_decimal")
        }})
      });
      
      console.log("searchFilter=", searchFilters);
      console.log("result from query:", resultList);
      let cords = [];
      result.forEach((e) => {
        // cords.push({lat: e.attributes.lat, lng: e.attributes.long});
        cords.push({lat: e.get("stringStuff")[3], lng: e.get("stringStuff")[4]});
      });   
      setCoOrdinates(cords);
      setDealsList(resultList);
      // console.log("coOrdinates:", coOrdinates);
      // console.log("dealsList:", dealsList);
    }
    
    fetchDealsList();
  }, [searchFilters]);

  const bookDeal = async function (id, price, amount) {
    let options = {
      contractAddress: "0x5d9EE4F42b214780850656Bc9A5A7948F415578a",
      functionName: "addTokenInvested",
      abi: [
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "id",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "numTokenInvest",
              "type": "uint256"
            }
          ],
          "name": "addTokenInvested",
          "outputs": [],
          "stateMutability": "payable",
          "type": "function"
        }
      ],
      params: {
        id: id,
        numTokenInvest: amount,
      },
      // msgValue: Moralis.Units.ETH(price * amount),
      msgValue: price * amount,
    }

    await contractProcessor.fetch({
      params: options,
      onSuccess: () => {
        handleSuccess();
      },
      onError: (error) => {
        handleError(error.data.message)
      }
    });
  }

  return (
    <>
      <div className="topBanner">
        <div>
          <Link to="/">
            <img className="logo" src={logo} alt="logo"></img>
          </Link>
        </div>
        <div className="searchReminder">
          <div className="filter">
            {searchFilters.projectType}
          </div>
          <div className="vl" />

          <div className="filter">
            {searchFilters.destination}
          </div>
          <div className="vl" />

          <div className="filter">
          {`
            ${searchFilters.startDate.toLocaleString("default", {month: "short",})}
            ${searchFilters.startDate.toLocaleString("default", {year: "numeric",})}
          `}
          </div>
          <div className="vl" /> 

          <div className="filter">
            ${searchFilters.minAmount}
          </div> 

          <div className="searchFiltersIcon">
            <Icon fill="#ffffff" size={20} svg="search" />
          </div>
        </div>
        <div className="lrContainers">
          {account &&
          <User account={account} />
          }
          <ConnectButton />
        </div>
      </div>

      <hr className="line"/>

      <div className="rentalsContent">
        <div className="rentalsContentL">
          Deals available in your area
          {dealsList &&
          dealsList.map( (e,i) => {
            return (
              <>
                <hr className="line2"/>
                <div className={highLight==i ? "rentalDivH" : "rentalDiv"}>
                  <img className="rentalImg" src={e.attributes.imgUrl}></img>
                  <div className="rentalInfo">
                    <div className="rentalTitle">{e.attributes.name}</div>
                    <div className="rentalDesc">{e.attributes.description}</div>
                    <div className="rentalDesc">ROI: {e.attributes.ROI}%</div>
                    <div className="rentalDesc">Holding period: {e.attributes.holdingPeriodByMonth} months</div>
                    <div className="bottomButton">
                      <Button 
                      onClick={() => {
                        if(account) {
                          bookDeal(
                            e.attributes.uid_decimal.value.$numberDecimal,
                            e.attributes.tokenPrice * 10**14,
                            searchFilters.minAmount * 10000
                          )
                        } else {
                          handleNoAccount()
                        }
                      }}
                        text = "Test Invest"
                      />

                      <Button 
                      onClick={() => {window.open('https://investor.wisptoken.com/sign-in', '_blank')}}
                        text = "Invest STO"
                      />
                      
                      <div className="price">
                        <Icon fill="#808080" size={10} svg="matic" /> {e.attributes.tokenPrice} / Token
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          })
          }
        </div>

        <div className="rentalsContentR">
          <RentalsMap locations={coOrdinates} setHighLight={setHighLight} />
        </div>
      </div>
    </>
  );
};

export default Listing;
