import React from "react";
import {Icon, Modal, Card} from "web3uikit";
import {useState, useEffect} from "react";
import {useMoralis} from "react-moralis";

function User({account}) {
  const [isVisible, setVisible] = useState(false);
  const {Moralis} = useMoralis();
  const [userDeals, setUserDeals] = useState();
  
  useEffect(() => {
    async function fetchDeals() {
      const Deals = Moralis.Object.extend("NewBookings");  
      const query = new Moralis.Query(Deals);
      query.equalTo("booker", account);
      const result = await query.find();
      // console.log("query result=", result);
      setUserDeals(result);
    }

    fetchDeals();
  }, [isVisible]);

  return (
    <>
      <div onClick={() => setVisible(true)}>
        <Icon fill="#000000" size={24} svg="user" />
      </div>

      <Modal
        onCloseButtonPressed={() => setVisible(false)}
        hasFooter={false}
        title="Your investment"
        isVisible={isVisible}
      >
      <div style={{display:"flex", justifyContent:"start", flexWrap:"wrap", gap:"10px"}}>
      {userDeals &&
      userDeals.map((e) => {
        return(
          <div style={{width: "200px"}}>
            <Card
              isDisabled
              title={e.attributes.state}
              description={`Invested ${Number(e.attributes.numTokenInvest_decimal.value.$numberDecimal)/10000} Tokens`}
            >
              <div>
                <img 
                  width="180px"
                  src={e.attributes.imgUrl}
                />
              </div>
            </Card>
          </div>
        )
      })
      }
      </div>
      </Modal>
    </>
  );
}

export default User;
