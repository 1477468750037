import React from "react";
import "./Listing.css";
import "./AddNew.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import logo from "../images/WispLogo.png";
import { ConnectButton, Icon, Input, Button, useNotification } from "web3uikit";
import Select from 'react-select';
import { useState, useEffect} from "react";
import { useMoralis, useWeb3ExecuteFunction } from "react-moralis";
import User from "../components/User";
// import MyGoogleMap from "../components/MyGoogleMap";

const AddNew = () => {
  const [zoom, setZoom] = useState(4);
  const [projectType, setProjectType] = useState("Wireless Internet");
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [contribution, setContribution] = useState(0);
  const {Moralis, account} = useMoralis();

  return (
    <>
      <div className="topBanner">
        <div>
          <Link to="/">
            <img className="logo" src={logo} alt="logo"></img>
          </Link>
        </div>
        <div className="tabContent">
        <div className="addFields">
          <div className="inputs">
            Project Type
            <Select 
              defaultOptionIndex={0}
              onChange={(data) => setProjectType(data.label)}
              options={[               
                {
                  id: "wisp",
                  label: "Wireless Internet"
                },
                {
                  id: "solar",
                  label: "Solar"
                },           
                {
                  id: "wind",
                  label: "Wind"
                },
              ]}
            />
          </div>
          <div className="vl" />

          <div className="inputs">
            Zoom
            <Input
                value={4}
                name="ZoomIn"
                type="number"
                onChange={(event) => setZoom(Number(event.target.value))}
            />            
          </div>
            <div className="vl" /> 

          <div className="inputs">
            Latitude
            <Input
                value={43}
                name="lat"
                type="number"
                onChange={(event) => setLat(Number(event.target.value))}
            />            
          </div>
          <div className="vl" />

          <div className="inputs">
            Longitude
            <Input
                value={-82}
                name="lng"
                type="number"
                onChange={(event) => setLng(Number(event.target.value))}
            />            
          </div>
          <div className="vl" />          

          <div className="inputs">
            Potential Contribution ($)
            <Input
                value={100}
                name="contribution"
                type="number"
                onChange={(event) => setContribution(Number(event.target.value))}
            />            
          </div>

        </div>
        </div>

        <div className="lrContainers">
          {account &&
          <User account={account} />
          }
          <ConnectButton />
        </div>
      </div>

      <hr className="line"/>

      <div className="rentalsContent">
        <div className="rentalsContentL">
          <p>Left side search location on map</p>
        </div>

        <div className="rentalsContentR">
          <p>Right side heat map</p>
        </div>
      </div>

    </>
  );
};

export default AddNew;